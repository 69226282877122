import React from 'react';
import Link from 'next/link';
import config from '@root/config';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function HelpSupport() {
  const {t} = LocaleService.useLocale();

  const phoneLink = () => {
    return `tel:${config.phone_contact?.value}`;
  };

  const emailLink = () => {
    return `mailto:${config.email_contact.value}?subject=Feedback&body=Please%20mail%20your%20feedback`;
  };

  return (
    <div className={styles.helpnsupport}>
      <Link href={emailLink()}>
        <div className={styles.helpnsupport_item}>
          <IconBox
            name="information-outline"
            className={styles.helpnsupport_icon}
          />
          <div className={styles.helpnsupport_text}>
            <span className={styles.helpnsupport_title}>
              {t('footer_help_center')}
            </span>
            <span className={styles.helpnsupport_value}>
              info@barakatfresh.ae
            </span>
          </div>
        </div>
      </Link>

      <Link href={phoneLink()}>
        <div className={styles.helpnsupport_item}>
          <IconBox name="phone-outline" className={styles.helpnsupport_icon} />
          <div className={styles.helpnsupport_text}>
            <span className={styles.helpnsupport_title}>
              {t('footer_phone_support')}
            </span>
            <span className={styles.helpnsupport_value}>800 2272 528</span>
            {/* <span className={styles.helpnsupport_value}>0568707409</span> */}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HelpSupport;
