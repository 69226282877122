import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

function Layout({header, footer, withTopbar, children}) {
  return (
    <div className={clsx(styles.layout, header && styles.hasheader, withTopbar && styles.withnotice)}>
      {header && <div className={styles.layout_head}>{header}</div>}
      <div className={clsx(styles.layout_content)}>{children}</div>
      {footer && <div className={styles.layout_footer}>{footer}</div>}
    </div>
  );
}

export default Layout;
