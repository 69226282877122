import Link from 'next/link';
import {map} from 'lodash';
import React, {useEffect, useState} from 'react';
// import BaseService from '@root/services/base';
// import {isValidArray} from '@root/libs/utils';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function SiteLinks({categories}) {
  const {t} = LocaleService.useLocale();
  // const [catsList, setCatList] = useState([]);

  // useEffect(() => {
    // if (isValidArray(categories)) return;

    // BaseService.getCategories()
    //   .then(result => setCatList(result?.data?.results))
    //   .catch(error => console.log(error));
  // }, [categories]);

  return (
    <div className={styles.sitelinks}>
      <div className={styles.sitelinks_box}>
        <ul className={styles.sitelinks_list}>
          <li className={styles.sitelinks_item}>
            <Link href="/about-us">
              <span className={styles.sitelinks_link}>
                {t('footer_link_about_us')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/contact-us">
              <span className={styles.sitelinks_link}>
                {t('footer_link_contact_us')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/privacy-policy">
              <span className={styles.sitelinks_link}>
                {t('footer_link_privacy_policy')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/delivery-policy">
              <span className={styles.sitelinks_link}>
                {t('footer_link_delivery_policy')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/payment-method">
              <span className={styles.sitelinks_link}>
                {t('footer_link_payment_methods')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/return-policy">
              <span className={styles.sitelinks_link}>
                {t('footer_link_refund_return')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/terms-and-conditions">
              <span className={styles.sitelinks_link}>
                {t('footer_link_term_conditions')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/disclaimer">
              <span className={styles.sitelinks_link}>
                {t('footer_link_disclaimer')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/barakat-rewards">
              <span className={styles.sitelinks_link}>
                {t('footer_link_barakat_rewards')}
              </span>
            </Link>
          </li>
          <li className={styles.sitelinks_item}>
            <Link href="/barakat-rewards-faq">
              <span className={styles.sitelinks_link}>
                {t('footer_link_rewards_faq')}
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.sitelinks_box}>
        <ul className={styles.sitelinks_list}>
          {map(categories, category => {
            if (category?.request_path) {
              return (
                <li key={category?.id} className={styles.sitelinks_item}>
                  <Link href={category?.request_path}>
                    <span className={styles.sitelinks_link}>
                      {category?.name}
                    </span>
                  </Link>
                </li>
              );
            } else {
              return <></>;
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default SiteLinks;
