import React from 'react';
import NoSSR from '@root/common/NoSSR';
import { DeviceTypes } from '@root/libs/mediamatcher';
import { isUndefined } from 'lodash';

function DeviceView({predicted, mobile = null, desktop = null}) {

  if(isUndefined(predicted)) return (
    <NoSSR>
      <DeviceTypes.Provider>
        <DeviceTypes.Matcher mobile={mobile}desktop={desktop} />
      </DeviceTypes.Provider>
    </NoSSR>
  );
  else return (
    <DeviceTypes.Provider>
      <DeviceTypes.ServerRender predicted={predicted}>
        <DeviceTypes.Matcher mobile={mobile} desktop={desktop} />
      </DeviceTypes.ServerRender>
    </DeviceTypes.Provider>
  )
}

export default DeviceView;
