import { isNull, isUndefined } from "lodash";
import { useEffect, useRef, useState } from "react";

export const userFocusAction = () => {
  const eleRef = useRef(null);
  const [active, setActive] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      setActive(eleRef.current && eleRef.current.contains(event.target));
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [eleRef])

  return {eleRef, active};
}

export const useRealEffect = (cb, data) => {
  const [depend, setDepend] = useState(null);

  const isUpdated = (usman) => {
    return hashGen(JSON.stringify(depend)) !== hashGen(JSON.stringify(usman));
  }

  const hashGen = (str) => {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  useEffect(() => {
    setDepend(data);
    if(isUndefined(data) || isNull(data) || !isUpdated(data)) return;
    return cb();
  }, data)
}