import clsx from 'clsx';
import React from 'react';
import {map} from 'lodash';
import { isValidArray } from '@root/libs/utils';

import styles from './styles.module.scss';
import LocaleService from '@root/services/locale';

function Suggestions({history, trending, onPressKeyword, onPressClear}) {
  const {t} = LocaleService.useLocale();

  return (
    <>
      {isValidArray(history) && (
        <div className={clsx(styles.history, 'br_mb30')}>
          <div className={styles.history_head}>
            <span className={styles.history_heading}>{t('search_suggestion_history_title')}</span>
            <a onClick={onPressClear} className={styles.history_clear}>{t('search_suggestion_history_clear')}</a>
          </div>
          <ul className={styles.history_list}>
            {map(history, item => {
              return (
                <li key={item} className={styles.history_item}>
                  <a onClick={() => onPressKeyword(item)} className={styles.history_link}>{item}</a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {isValidArray(trending) && (
        <div className={styles.trending}>
          <div className={styles.trending_head}>
            <span className={styles.trending_heading}>{t('search_suggestion_trending_title')}</span>
          </div>
          <ul className={styles.trending_list}>
            {map(trending, suggestion => {
              return (
                <li key={suggestion} className={styles.trending_item}>
                  <a onClick={() => onPressKeyword(suggestion)} className={styles.trending_link}>{suggestion}</a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Suggestions;
