import { first, isNull } from 'lodash';
import React, {useEffect, useRef} from 'react';

function TopFixed(props) {
  const fixedRef = useRef();
  const options = {root: null, threshold: [0, 1]};

  useEffect(() => {
    if (isNull(fixedRef.current)) return;
    fixedRef.current.style.height = `${fixedRef.current.offsetHeight}px`;

    let fixedObsv = new IntersectionObserver(entries => {
      let child = first(entries[0]['target'].children);
      if(entries[0]['intersectionRatio'] === 0) child.classList.add('fixed');
      if(entries[0]['intersectionRatio'] === 1) child.classList.remove('fixed');
    }, options);
    fixedObsv.observe(fixedRef.current);
  }, [fixedRef]);

  return (
    <div ref={fixedRef}>
      <div>{props.children}</div>
    </div>
  );
}

export default TopFixed;
