import React from 'react';
import Link from 'next/link';
import { get } from 'lodash';
import CartService from '@root/services/cart';
import IconBox from '@root/common/IconBox';
import LocaleService from "@root/services/locale";

import styles from './styles.module.scss';

function CartCount() {
  const { t } = LocaleService.useLocale();
  const { cartData } = CartService.useCartData();

  const itemCount = () => {
    return get(cartData, ['items_count'], 0);
  }

  return (
    <Link href="/cart">
      <span className={styles.cartcount}>
        <span className={styles.cartcount_text}>{t('cart_title')}</span>
        <IconBox className={styles.cartcount_icon} name="cart-outline" />
        <span className={styles.cartcount_value}>{itemCount()}</span>
      </span>
    </Link>
  )
}

export default CartCount