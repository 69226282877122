import React from 'react';
import Link from 'next/link';
import AuthService from '@root/services/auth';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function UserOpts() {
  const {t} = LocaleService.useLocale();
  const authorized = AuthService.useAuth();

  return (
    <div className={styles.useropts}>
      {!authorized ? <Link href='/login'>
        <span className={styles.useropts_link}>
          <span className={styles.useropts_text}>{t('account_sign_in')}</span>
          <IconBox name="account-outline" className={styles.useropts_icon} />
        </span>
      </Link> : <Link href='/account'>
        <span className={styles.useropts_link}>
          <span className={styles.useropts_text}>{t('account_title')}</span>
          <IconBox name="account-outline" className={styles.useropts_icon} />
        </span>
      </Link>}
    </div>
  );
}

export default UserOpts;
