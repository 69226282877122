import clsx from 'clsx';
import {firstValueFrom} from 'rxjs';
import { debounce, get } from "lodash";
import { useRouter } from 'next/router';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';
import SearchService from '@root/services/search';
import { userFocusAction } from '@root/libs/hooks';
import LoggerService from '@root/services/logger';
import React, {useEffect, useState, useCallback} from 'react';

import styles from './styles.module.scss';
import Suggestions from '../Suggestions';

function SearchBar() {
  const router = useRouter();
  const {t} = LocaleService.useLocale();
  const {eleRef, active} = userFocusAction();
  const [history, setHistory] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const searchQuery = get(router.query, ['key'], '');
  const [searchText, setSearchText] = useState(searchQuery);

  useEffect(() => {
    firstValueFrom(SearchService.trendingSearch())
      .then(result => setSuggestions(result.data))
      .catch(error => LoggerService.logError('trending search error!', error));

    let history_sub = SearchService.$history.subscribe(values => {
      setHistory(values);
    });

    return () => history_sub.unsubscribe();
  }, []);

  const debounced = useCallback(debounce((value) => {
    console.log('Updated search: Show suggestions');
  }, 500), []);

  const onPressKeyword = text => {
    setSearchText(text);
    router.replace({
      pathname: 'search',
      query: {key: text},
    });
  };

  const onChangeText = event => {
    let value = event.target.value;
    setSearchText(value);
    debounced(value);
  }

  const onClearHistory = () => {
    SearchService.clearHistory();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setHistory([searchText, ...history]);
    SearchService.saveKeyword(searchText);
    router.push({pathname: 'search', query: {key: searchText}});
  }

  return (
    <div ref={eleRef} className={styles.search}>
      <form onSubmit={onSubmit} className={styles.search_form}>
        <input
          type="text"
          value={searchText}
          onChange={onChangeText}
          className={styles.search_input}
          placeholder={t('home_what_are_you_looking_for')}
        />
        <input type="submit" value="go" className={styles.search_button} />
        <IconBox name="magnify" className={styles.search_icon} />
      </form>
      <div className={clsx(styles.search_dropdown, active && styles.active)}>
        <div className={styles.search_container}>
          <Suggestions
            history={history}
            trending={suggestions}
            onPressClear={onClearHistory}
            onPressKeyword={onPressKeyword}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
