import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';
// import Newsletter from './components/NewsLetter';
import SocialLink from './components/SocialLinks';
import AppDownload from './components/AppDownload';
import HelpSupport from './components/HelpSupport';
import SiteLinks from './components/SiteLinks';
import PaymentOptions from '../components/PaymentOptions';
import IconBox from '@root/common/IconBox';
import config from '@root/config';
import Link from 'next/link';

function Footer({categories}) {

  const whatsappLink = () => {
    return `https://wa.me/${config.whatsapp_contact?.value}?text=Please%20message%20your%20feedback`
  }

  return (
    <div className={styles.footer}>
      <div className="br_wrapper br_pt20">
        <div className={styles.footer_top}>
          <div className={styles.footer_social}><SocialLink /></div>
          <div className={styles.footer_applinks}><AppDownload /></div>
        </div>
        <span className={clsx(styles.footer_hr, 'br_mb20', 'br_mt20')}></span>
        <div className={styles.footer_body}>
          <div className={styles.footer_links}><SiteLinks categories={categories} /></div>
          <div className={styles.footer_help}><HelpSupport /></div>
        </div>
        <span className={clsx(styles.footer_hr, 'br_mt20', 'br_mb10')}></span>
        <div className={styles.footer_bottom}>
          <PaymentOptions />
          <span className={styles.copyright}>Copyright © 2020-Present Barakat Fresh, All Rights Reserved.</span>
        </div>
      </div>
      <Link href={whatsappLink()} target="_blank">
        <span className={styles.floating_button}>
          <IconBox name='whatsapp' />
        </span>
      </Link>
    </div>
  );
}

export default Footer;
