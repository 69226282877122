import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import NoSSR from '@root/common/NoSSR';
import LocaleService from '@root/services/locale';
import {DeviceTypes} from '@root/libs/mediamatcher';

import styles from './styles.module.scss';

function LogoLink({link = '/'}) {
  const {t} = LocaleService.useLocale();

  return (
    <NoSSR>
      <DeviceTypes.Provider>
        <Link href={link}>
          <span className={styles.logolink}>
            <DeviceTypes.Matcher
              mobile={
                <Image
                  priority
                  alt="Barakat"
                  src={
                    LocaleService.isArabic
                      ? require('@root/assets/images/barakat-logo-ar-primary.png')
                      : require('@root/assets/images/barakat-logo-en-primary.png')
                  }
                />
              }
              desktop={
                <Image
                  priority
                  alt="Barakat"
                  src={
                    LocaleService.isArabic
                      ? require('@root/assets/images/barakat-logo-ar-white.png')
                      : require('@root/assets/images/barakat-logo-en-white.png')
                  }
                />
              }
            />
          </span>
        </Link>
      </DeviceTypes.Provider>
    </NoSSR>
  );
}

export default LogoLink;
