import config from '@root/config';
import LocaleService from './locale';
import RequestService from './request';
import {BehaviorSubject} from 'rxjs';
import {isValidArray} from '@root/libs/utils';
import {hasIn, isNull, take, pull} from 'lodash';

class Search {
  constructor() {
    this.limit = 15;
    this.history = new BehaviorSubject([]);
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get $history() {
    this.history.next(this._getStorage());
    return this.history.asObservable();
  }

  saveKeyword(keyword) {
    let values = this.history.getValue();
    // remove all values that exceeds limit
    let taken = take(values, this.limit);
    // remove repeating values
    pull(taken, keyword);
    let newvalue = [keyword, ...taken];
    this.history.next(newvalue);
    this._setStorage(newvalue);
  }

  clearHistory() {
    this.history.next([]);
    this._setStorage([]);
  }

  trendingSearch() {
    return RequestService.$get(`${config.base_path}_svc/api/es/v1/trending`, {
      params: {language: LocaleService.current, country: 'AE'},
    });
  }

  newelasticSearch(query, next) {
    return RequestService.$post(`${config.base_path}_svc/api/es/v1/search/`, {
      body: {
        query: query,
        source: 'apps',
        country: 'AE',
        language: LocaleService.current,
        start: next,
        v: 2,
      },
    });
  }

  elasticSearch(query, city_id, next) {
    return RequestService.$post(`${config.base_path}_svc/api/es/v1/search/`, {
      body: {
        query: query,
        source: 'apps',
        country: 'AE',
        city_id: city_id,
        language: LocaleService.current,
        start: next,
      },
    });
  }

  hasChildren(product) {
    if (!hasIn(product, ['child'])) return false;
    return isValidArray(product.child);
  }

  _getStorage() {
    let value = window.localStorage.getItem(config.searchHistoryKey);
    return isNull(value) ? [] : JSON.parse(value);
  }

  _setStorage(value) {
    window.localStorage.setItem(config.searchHistoryKey, JSON.stringify(value));
  }
}
const SearchService = new Search();
export default SearchService;
