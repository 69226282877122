import { createMediaMatcher } from "react-media-match";

export const DeviceTypes = createMediaMatcher({
  mobile: '(max-width: 768px)',
  desktop: '(min-width: 868px)',
});

export const MediaQueries = createMediaMatcher({
  small: "(max-width: 768px)",
  medium: "(max-width: 990px)",
  large: "(max-width: 1200px)",
});