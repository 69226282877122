import clsx from 'clsx';
import React from 'react';
import NoSSR from '@root/common/NoSSR';
import LogoLink from '@root/common/LogoLink';
import TopFixed from '@root/common/TopFixed';
import LangSwitch from '@root/common/LangSwitch';
import LocaleService from '@root/services/locale';

import SearchBar from './components/SearchBar';
import CartCount from './components/CartCount';
import UserOpts from './components/UserOpts';

import styles from './styles.module.scss';

const HeaderDesktop = ({notice, nextslot, menu}) => {
  const {t} = LocaleService.useLocale();

  return (
    <div className={styles.header}>
      {(notice || nextslot) && (
        <div className={styles.header_top}>
          <div className="br_wrapper">
            <div
              className={clsx(
                styles.header_top_box,
                nextslot && styles.delivery,
              )}>
              {notice && (
                <div className={styles.header_top_notice}>{notice}</div>
              )}
              {nextslot && (
                <div className={styles.header_top_delivery}>{nextslot}</div>
              )}
            </div>
          </div>
        </div>
      )}

      <TopFixed>
        <div className={styles.header_middle}>
          <div className="br_wrapper">
            <div className={styles.header_box}>
              <div className={styles.header_logo}>
                <LogoLink />
              </div>
              <div className={styles.header_search}>
                <SearchBar />
              </div>
              <div className={styles.header_options}>
                <span className={styles.header_option}>
                  <LangSwitch />
                </span>
                <NoSSR>
                  <span className={styles.header_sep}></span>
                  <span className={styles.header_option}>
                    <UserOpts />
                  </span>
                  <span className={styles.header_sep}></span>
                  <span className={styles.header_option}>
                    <CartCount />
                  </span>
                </NoSSR>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.header_bottom}>
          <div className="br_wrapper">
            <div className={styles.header_bottom_box}>{menu}</div>
          </div>
        </div>
      </TopFixed>
    </div>
  );
};

export default HeaderDesktop;
