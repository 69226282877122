import React from 'react';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import LocaleService from "@root/services/locale";

function LangSwitch() {
  const router = useRouter();

  return (
    <div className={styles.language}>
      {LocaleService.current === 'en' && <a href={`/ar${router.asPath}`} className={styles.language_text} style={{"fontFamily": 'Tajawal'}}>العربية</a>}
      {LocaleService.current === 'ar' && <a href={`/en${router.asPath}`} className={styles.language_text}>English</a>}
    </div>
  )
}

export default LangSwitch