import React from 'react';
import dynamic from 'next/dynamic';
import LocaleService from "@root/services/locale";

const NoSsr = props => (
  <React.Fragment>{props.children}</React.Fragment>
)

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
})