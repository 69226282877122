import React from 'react';
import Image from 'next/image';

import styles from './styles.module.scss';

function PaymentOptions() {
  return (
    <div className={styles.payments}>
      <ul className={styles.payments_list}>
        <li className={styles.payments_item}>
          <a className={styles.payments_link}><Image alt='AMEX Card' width={294} height={156} src={require('@root/assets/images/am_ex.png')} /></a>
        </li>
        <li className={styles.payments_item}>
          <a className={styles.payments_link}><Image alt='Master Card' width={294} height={156} src={require('@root/assets/images/mc.png')} /></a>
        </li>
        <li className={styles.payments_item}>
          <a className={styles.payments_link}><Image alt='Cash Payment' width={294} height={156} src={require('@root/assets/images/cash.png')} /></a>
        </li>
        <li className={styles.payments_item}>
          <a className={styles.payments_link}><Image alt='Visa Card' width={294} height={156} src={require('@root/assets/images/visa_card.png')} /></a>
        </li>
      </ul>
    </div>
  );
}

export default PaymentOptions;
