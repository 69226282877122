import React from 'react';
import Image from 'next/image';

import styles from './styles.module.scss';

function AppDownload() {
  return (
    <div className={styles.barakatapps}>
      <ul className={styles.barakatapps_list}>
        <li className={styles.barakatapps_item}>
          <a href='https://apps.apple.com/us/app/id1510001708' target="_blank" rel="noreferrer" className={styles.barakatapps_link}>
            <Image
              width={465}
              height={144}
              alt="iOS App link"
              src={require('@root/assets/images/ios_app.png')}
            />
          </a>
        </li>
        <li className={styles.barakatapps_item}>
          <a href='https://play.google.com/store/apps/details?id=com.barakat&hl=en' target="_blank" rel="noreferrer" className={styles.barakatapps_link}>
            <Image
              width={465}
              height={144}
              alt="Android App link"
              src={require('@root/assets/images/and_app.png')}
            />
          </a>
        </li>
        <li className={styles.barakatapps_item}>
          <a href='https://appgallery.cloud.huawei.com/ag/n/app/C104651671?channelId=Web&id=986ee54dd855404290fdeb73a48ac83a&s=76DD388BFE8384D96F1C5694430BEC616708D316C3B465BA45C8B508F4F28D07&detailType=0&v=&callType=AGDLINK&installType=0000' target="_blank" rel="noreferrer" className={styles.barakatapps_link}>
            <Image
              width={465}
              height={144}
              alt="Huawei App link"
              src={require('@root/assets/images/huawei_app.png')}
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default AppDownload;
