import React from 'react';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';
import config from '@root/config';

function SocialLink() {
  const {t} = LocaleService.useLocale();

  const whatsappLink = () => {
    return `https://wa.me/${config.whatsapp_contact?.value}?text=Please%20message%20your%20feedback`
  }

  return (
    <div className={styles.sociallinks}>
      <span className={styles.sociallinks_heading}>{t('footer_contact_us')}</span>
      <ul className={styles.sociallinks_list}>
        <li className={styles.sociallinks_item}>
          <a target="_blank" rel="noreferrer" href='https://www.facebook.com/BarakatUAE' className={styles.sociallinks_link}>
            <IconBox name="facebook" />
          </a>
        </li>
        <li className={styles.sociallinks_item}>
          <a target='_blank' rel="noreferrer" href='https://www.youtube.com/channel/UCGbS-33t-ds7Pal1VF9Nf6g' className={styles.sociallinks_link}>
            <IconBox name="youtube" />
          </a>
        </li>
        <li className={styles.sociallinks_item}>
          <a target="_blank" rel="noreferrer" href='https://twitter.com/barakatme?lang=en' className={styles.sociallinks_link}>
            <IconBox name="twitter" />
          </a>
        </li>
        <li className={styles.sociallinks_item}>
          <a target="_blank" rel="noreferrer" href='https://www.instagram.com/barakatme/' className={styles.sociallinks_link}>
            <IconBox name="instagram" />
          </a>
        </li>
        <li className={styles.sociallinks_item}>
          <a target="_blank" rel="noreferrer" href={whatsappLink()} className={styles.sociallinks_link}>
            <IconBox name="whatsapp" />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialLink;
